
.table a {
    text-decoration: none !important;
    color: #2a468d !important;
}

.table a:hover {
    color: #1f2f6c !important;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #D3D7E1 !important; /* Jasny szary */
    padding-top: 20px;
    color: #3B3F4E; /* Grafitowy */
}

.navbar {
    background-color: #2A468D; /* Ciemny niebieski */
}

.navbar-brand img {
    height: 40px;
}

.container {
    max-width: 1100px;
    margin: auto;
}

.content {
    padding: 20px;
    background-color: #FFFFFF; /* Biały */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

h4, h5, h6 {
    font-weight: 500;
}

h4 {
    color: #2A468D !important; /* Ciemny niebieski */
}

h6 {
    color: #4A73C2 !important; /* Jasny niebieski */
}

.stat-card {
    background-color: #1AB6A7; /* Turkusowy */
    color: #FFFFFF;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.card-title {
    color: #2A468D; /* Ciemny niebieski */
    font-weight: 500;
}

.card-text {
    color: #3B3F4E; /* Grafitowy */
}

.card {
    margin-bottom: 20px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-label {
    color: #2A468D !important; /* Ciemny niebieski */
    font-weight: 500 !important;
}

.form-control {
    border-color: #D3D7E1; /* Jasny szary */
}

.table th, .table td {
    vertical-align: middle;
}

.table th {
    /*background-color: #2A468D !important; !* Ciemny niebieski *!*/
    /*color: #FFFFFF !important;*/
    background-color: #FFFFFF !important; /* Ciemny niebieski */
    color: #2A468D !important;
}

.pagination {
    justify-content: center;
}

.page-link {
    color: #2A468D; /* Ciemny niebieski */
}

.page-link:hover {
    background-color: #F2B134; /* Złoty */
    color: #FFFFFF; /* Biały */
}

.page-item.active .page-link {
    background-color: #1AB6A7; /* Turkusowy */
    border-color: #1AB6A7; /* Turkusowy */
    color: #FFFFFF; /* Biały */
}

.page-item.disabled .page-link {
    color: #FF6F61; /* Jasny koral */
}

.btn-close {
    background-color: #FFFFFF !important;
    border: none !important;
    color: white !important;
}

/* Buttons */
.btn-primary {
    background-color: #2A468D !important; /* Ciemny niebieski */
    border: none;
    color: #FFFFFF !important;
}

.btn-primary:hover {
    background-color: #1e336d !important; /* Ciemniejszy niebieski */
}

.btn-secondary {
    background-color: #FF6F61 !important; /* Jasny koral */
    border: none !important;
    color: #FFFFFF !important;
}

.btn-secondary:hover {
    background-color: #e05a4d !important; /* Ciemniejszy koral */
}

.btn-success {
    background-color: #0ca453 !important; /* Turkusowy */
    border: none !important;
    color: #FFFFFF !important;
}

.btn-success:hover {
    background-color: #1f9b55 !important; /* Ciemniejszy turkusowy */
}

.btn-warning {
    background-color: #F2B134 !important; /* Złoty */
    border: none;
    color: #FFFFFF !important;
}

.btn-warning:hover {
    background-color: #c89529 !important; /* Ciemniejszy złoty */
}

.btn-danger {
    background-color: #b01105 !important; /* Jasny koral */
    border: none;
    color: #FFFFFF !important;
}

.btn-danger:hover {
    background-color: #bd2a26 !important; /* Ciemniejszy koral */
}

.btn-outline-primary {
    border-color: #F2B134 !important; /* Złoty */
    color: #F2B134 !important; /* Złoty */
}

.btn-outline-primary:hover {
    background-color: #F2B134 !important; /* Złoty */
    color: #FFFFFF !important; /* Biały */
}

.btn-outline-light {
    border-color: #FF6F61 !important; /* Jasny koral */
    color: #FF6F61 !important; /* Jasny koral */
}

.btn-outline-light:hover {
    background-color: #FF6F61 !important;
    color: #FFFFFF !important;
}

.btn-info {
    background-color: #1AB6A7 !important; /* Turkusowy */
    color: #FFFFFF !important;
    border: none;
}

.btn-info:hover {
    background-color: #148f83 !important; /* Ciemniejszy turkusowy */
}

.btn-sm {
    margin-right: 5px;
}

.pagination {
    justify-content: center;
}

.page-link {
    color: #2A468D; /* Ciemny niebieski */
}

.page-link:hover {
    background-color: #F2B134 !important; /* Złoty */
    color: #FFFFFF !important; /* Biały */
}

.page-item.active .page-link {
    background-color: #1AB6A7; /* Turkusowy */
    border-color: #1AB6A7; /* Turkusowy */
    color: #FFFFFF; /* Biały */
}

.page-item.disabled .page-link {
    color: #FF6F61; /* Jasny koral */
}

.modal-content {
    background-color: #FFFFFF; /* Biały */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    background-color: #2A468D; /* Ciemny niebieski */
    color: #FFFFFF;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-title {
    font-weight: 500;
}

/* Footer */
.footer {
    margin-top: 40px;
    padding: 20px;
    text-align: center;
    background-color: #2A468D; /* Ciemny niebieski */
    color: #FFFFFF; /* Biały */
    border-radius: 8px;
}

.badge {
    font-size: 14px;
}

/* Badge */
.badge.bg-primary {
    background-color: #1AB6A7 !important; /* Turkusowy */
}

.badge.bg-secondary {
    background-color: #FF6F61 !important; /* Jasny koral */
}

.pdfWrapper iframe {
    border: 1px solid #D3D7E1; /* Jasny szary */
    border-radius: 8px;
}

.btn-group-responsive .btn {
    flex: 1;
}

.icon_margin-right {
    margin-right: 5px;
}